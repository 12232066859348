.grid {

	display: grid;
	gap: 3rem;
	grid-template-columns: 18rem 1fr;

	@media all and (max-width: 640px) {
		grid-template-columns: 15rem 1fr;
	}

	@media all and (max-width: 480px) {
		grid-template-columns: 1fr;
		row-gap: 1.5rem;

		>:nth-child(2n):not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}

}
