.header {
	margin-bottom: -0.5rem;
	padding-bottom: 0.5rem;
	position: sticky;
	top: 0;
	z-index: 105;

	&::after {
		background: var(--base-colour);
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
