.MaterialTable {

	:global(.MuiPaper-root > .MuiBox-root > .MuiToolbar-root.MuiToolbar-regular) {
		padding-left: 1.5rem !important;
		padding-right: 1.5rem !important;
	}

	:global(.MuiTableHead-root) {
		display: var(--table-head-display);

		:global(.MuiTableCell-root) {
			justify-content: flex-start !important;
		}

		:global(.MuiTableRow-root) {
			grid-auto-flow: column !important;
			grid-template-columns: var(--table-columns-head) !important;
		}
	}

	:global(.MuiTableHead-root), :global(.MuiTableBody-root) {

		:global(.MuiTableCell-root) {
			align-content: center;
			display: grid;
			justify-content: var(--table-cell-justify-content);
			padding-left: 1.5rem !important;
			padding-right: 1.5rem !important;
			width: 100% !important;
		}

		:global(.MuiTableRow-root) {
			display: grid !important;
			grid-auto-flow: var(--table-flow);
			grid-template-columns: var(--table-columns);

			&:global(:not(:first-child)) {
				:global(.MuiTableCell-root:first-child) {
					padding-top: var(--table-cell-padding);
				}
			}

			&:global(:not(:last-child)) {
				:global(.MuiTableCell-root:last-child) {
					padding-bottom: var(--table-cell-padding);
				}
			}

			:global(.MuiTableCell-root:not(:last-child)) {
				border-width: var(--table-cell-border) !important;
			}

		}

	}

	:global(.MuiTablePagination-root .MuiIconButton-label) {
		height: 2.5rem;
		width: 2.5rem;
	}

	:global(.MuiTableRow-head .MuiTableCell-head) {
		font-weight: bold;
	}

	@media all and (max-width: 480px) {

		:global(.MuiTableFooter-root .MuiTableCell-footer) {
			float: none !important;
		}

		:global(.MuiTableFooter-root) {

			:global(.MuiTableRow-root) {
				display: grid;
				padding-top: 1.5rem;
			}

			:global(.MuiToolbar-root) {
				justify-content: center;
				justify-items: center;
				display: grid !important;
			}

		}

	}

}
