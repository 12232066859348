$MOBILE_BP: 480px;

$MOBILE_GAP: 0.75rem;

.nmpisContainer {
	@media all and (max-width: $MOBILE_BP) {
		gap: $MOBILE_GAP !important;
	}
}

.nmpisButtonsContainer {

	gap: $MOBILE_GAP !important;

	:global(> :last-child) {
		order: -1;
	}

	@media all and (min-width: $MOBILE_BP) {
		gap: 3rem !important;
		grid-template-columns: max-content 1fr !important;
		justify-items: flex-start;

		:global(> :last-child) {
			order: 1;
		}
	}

}
