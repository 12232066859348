.contenteditable {

	display: grid;
	grid-row-gap: 1.4rem;
	grid-template-columns: 100%;
	font-size: 1.4rem;
	font-family: Verdana;
	height: 48rem;
	list-style-position: inside;
	overflow-y: scroll;
	width: 100%;

	&, * {
		cursor: text;
		user-select: text !important;
	}

}
