.wrapper {
	grid-template-columns: 1fr;
	@media all and (min-width: 720px) {
		grid-template-columns: 12rem max-content;
	}
}

.right {
	grid-template-columns: 1fr;
	@media all and (min-width: 720px) {
		grid-template-columns: 21rem repeat(2, 12rem);
	}
}
