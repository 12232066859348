* {
	border: 0;
	box-sizing: border-box;
	font-family: inherit;
	font-size: 100%;
	margin: 0;
	max-width: 100%;
	outline: 0;
	padding: 0;
	vertical-align: baseline;

	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

	@media (orientation: landscape) {
		-webkit-text-size-adjust: 100%;
	}

	&:not(.MuiInputBase-input) {
		user-select: none;
	}

	&::selection {
		background: rgba(30,53,94,0.95);
		color: white;
	}
}

body {
	min-height: 100%;
	min-width: 100%;
	overflow-y: scroll !important;
	padding-right: 0 !important;
}

html {
	font-family: Verdana, sans-serif, serif;
	font-size: 62.5%;
	font-weight: normal;
	min-height: 100%;
	min-width: 100%;
}

code {
	font-family: monospace, sans-serif, serif;
}

input[type="file"] {
	font-size: 1.3rem;
}

summary {
	font-family: Verdana;
	font-weight: bold;
	
	&:focus {
		border: 0.125rem solid #1e355e;
		padding: 0.5rem;
	}
}

table td *, table th * {
	word-break: break-word !important;
}

td:not(:first-child), th:not(:first-child) {
	padding-left: 0.75rem !important;
}
td:not(:last-child), th:not(:last-child) {
	padding-right: 0.75rem !important;
}

th {
	white-space: nowrap;
}

.a-heron {
	color: #28a5d3;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.sec-force-right-rem-padding {
	padding-right: 1.5rem !important;
}

.height-fit-content {
	height: fit-content;
	height: -moz-fit-content;
}

.width-fit-content {
	width: fit-content;
	width: -moz-fit-content;
}

.MuiAlert-action .MuiIconButton-root {
	height: 3rem;
	width: 3rem;
}

.MuiAlert-icon {
	flex-shrink: 0;
}

.MuiAutocomplete-root .MuiIconButton-root {
	min-height: 2.25rem;
	min-width: 2.25rem;
}

.MuiAutocomplete-groupLabel {
	align-items: center;
	display: flex;
	font-size: 1rem !important;
	height: 3rem;
	line-height: initial !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.MuiAutocomplete-listbox li:first-child .MuiListSubheader-root {
	margin-bottom: 0.5rem;
}

.MuiAutocomplete-option {
	padding-bottom: 0.1rem !important;
	padding-top: 0.1rem !important;
}

.MuiAutocomplete-option .MuiTypography-root {
	word-break: break-word;
}

.MuiAutocomplete-popper {
	z-index: 10000000 !important;
}

.MuiChip-label {
	overflow-y: visible !important;
}

.MuiDialog-root {
	z-index: 10000 !important;
}

.MuiIconButton-root {
	box-sizing: content-box;
	min-width: 2.5rem;
}

.MuiLink-root:hover {
	cursor: pointer;
}

.MuiPopover-root {
	z-index: 10001 !important;
}

.MuiSnackbarContent-message {
	font-size: 1.4rem;
}

.MuiTableRow-root:last-child .MuiTableCell-root:not(.MuiTableCell-head):not(.MuiTablePagination-root) {
	border-bottom: none !important;
}

.MuiTooltip-popper {
	z-index: 10000 !important;
}

.MuiTooltip-tooltip {
	background: #646464 !important;
	font-size: 1.3rem !important;
	transition: none !important;
	text-align: center;
}

[data-sec-placeholder] {
	background: rgba(40, 165, 211, 0.3);
	display: inline-block;
	padding: 0.35rem 0.75rem;
}

@keyframes sec-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
