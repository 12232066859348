.Chart {
	position: relative;

	canvas {
		height: auto !important;
	}
}

.ChartTypography {
	position: absolute;
	left: 0;
	top: 0;
}
